<template>
  <a-row :gutter="[12, 12]">
    <a-col :span="24" style="width: 256px">
      <a-input v-model="searchForm.search" placeholder="计划编号, 名称, 设备编号, 名称" allowClear @pressEnter="search" />
    </a-col>
    <a-col :span="24" style="width: 256px">
      <a-range-picker v-model="searchDate" @change="search" />
    </a-col>
    <a-col :span="24" style="width: 200px">
      <a-select v-model="searchForm.type" placeholder="类型" allowClear style="width: 100%" @change="search">
        <a-select-option value="inspection">设备点检</a-select-option>
        <a-select-option value="maintenance">设备保养</a-select-option>
      </a-select>
    </a-col>
    <a-col :span="24" style="width: 100px">
      <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
    </a-col>

    <a-col :span="24">
      <a-table
        rowKey="id"
        size="small"
        :columns="columns"
        :dataSource="dataItems"
        :loading="dataLoading"
        :pagination="pagination"
        @change="changeTable"
      >
        <template slot="action" slot-scope="value, item">
          <table-action :item="item" />
        </template>
      </a-table>
    </a-col>
  </a-row>
</template>

<script>
import { maintenanceTaskList } from "@/api/equipment";
import moment from "moment";

export default {
  components: {
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined },
      searchDate: [undefined, undefined],
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: false,

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_value, _item, index) => index + 1,
        },
        {
          key: "maintenance_plan_number",
          title: "计划编号",
          dataIndex: ["maintenance_plan_item", "number"],
        },
        {
          key: "maintenance_plan_name",
          title: "计划名称",
          dataIndex: ["maintenance_plan_item", "name"],
        },
        {
          key: "maintenance_plan_type_display",
          title: "计划类型",
          dataIndex: ["maintenance_plan_item", "type_display"],
        },
        {
          key: "equipment_number",
          title: "设备编号",
          dataIndex: ["equipment_item", "number"],
        },
        {
          key: "equipment_name",
          title: "设备名称",
          dataIndex: ["equipment_item", "name"],
        },
        {
          key: "maintenance_item_number",
          title: "项目编号",
          dataIndex: ["maintenance_item_item", "number"],
        },
        {
          key: "maintenance_item_name",
          title: "项目名称",
          dataIndex: ["maintenance_item_item", "name"],
        },
        {
          key: "maintenance_plan_frequency",
          title: "频率",
          dataIndex: ["maintenance_plan_item", "frequency"],
        },
        {
          key: "maintenance_plan_frequency_unit_display",
          title: "频率单位",
          dataIndex: ["maintenance_plan_item", "frequency_unit_display"],
        },
        {
          key: "maintenance_plan_start_date",
          title: "开始日期",
          dataIndex: ["maintenance_plan_item", "start_date"],
        },
        {
          key: "maintenance_plan_end_date",
          title: "结束日期",
          dataIndex: ["maintenance_plan_item", "end_date"],
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 60,
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],

      createModalVisible: false,
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      const searchForm = {
        ...this.searchForm,
        start_date: this.searchDate[0] ? this.searchDate[0].clone().format("YYYY-MM-DD") : undefined,
        end_date: this.searchDate[1] ? this.searchDate[1].clone().add(1, "days").format("YYYY-MM-DD") : undefined,
      };

      this.dataLoading = true;
      maintenanceTaskList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
